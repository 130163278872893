<template>
  <div>
    <b-container class="mt-5 mb-5">
      <b-button variant="outline-primary" @click="deleteLocation()" class="float-right">
        Verwijder locatie
      </b-button>
      <h1 class="fs-4 font-weight-bold mb-4">Locatie wijzigen</h1>

      <ValidationObserver ref="form" slim>
        <b-card class="mb-3">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="mb-3">
              <label class="required">Naam locatie</label>
              <b-form-input v-model="form.name" />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="mb-3">
              <label class="required">Plaats</label>
              <b-form-input v-model="form.place" />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>

          <div class="mb-3">
            <label>Omschrijving</label>
            <VueTrix v-model="form.description"/>
          </div>

          <b-row>
            <b-col md="6">
              <div class="mb-3">
                <label>Header</label>
                <p class="text-muted">Optimaal formaat: 1920x1080 / 4:3 ratio (max. 5MB)</p>
                <DropFile v-model="form.header_image" accept="image/*" />
              </div>
            </b-col>
            <b-col md="6">
              <div class="mb-3">
                <label>Logo</label>
                <p class="text-muted">Optimaal formaat: 1024x1024 / 1:1 ratio (max. 5MB)</p>
                <DropFile v-model="form.header_logo" accept="image/*" />
              </div>
            </b-col>
          </b-row>

        </b-card>
      </ValidationObserver>

      <div class="clearfix">
        <b-button @click="saveLocation" variant="primary" class="float-right">
          Opslaan
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
  import { mapActions } from 'pinia'
  import { useShopStore } from '@/stores/shop'
  
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import VueTrix from "vue-trix";
  import DropFile from "@/components/DropFile.vue";

  export default {
      metaInfo: {
          title: 'Locatie wijzigen',
      },
      components: {
        DropFile,
        VueTrix,
        ValidationObserver,
        ValidationProvider,
      },
      data() {
          return {
            form: {
              name: '',
              description: '',
              place: '',
            }
          }
      },
      computed: {},
      methods: {
        ...mapActions(useShopStore, ['setLoading']),
        deleteLocation: function() {
          if( confirm("Weet je zeker dat je deze locatie wilt verwijderen?") ) {
              this.$axios.delete("https://api.tixgo.nl/locations/" + this.$route.params.id)
                .then( response => {
                  response;
                  this.$router.push('/manage/organisation/locations')
                }
              );
          }
        },
        getLocation: function() {
          this.setLoading(true);
          this.$axios.get("https://api.tixgo.nl/locations/" + this.$route.params.id)
            .then( response => {
              this.form = response.data;
              this.setLoading(false);
            }
          );
        },
        async saveLocation() {

          let check = await this.$refs['form'].validate();

          if (!check) {
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
            
            return;
          }
          
          let id = this.$route.params.id;
          this.$axios.put("https://api.tixgo.nl/locations/" + id, this.form)
            .then( response => {
              response;
              this.$router.push('/manage/organisation/locations')
            }
          );
        }
      },
      created() {
        this.getLocation();
      }
  }
</script>